// entry_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["form", "vr", "school", "schoolOther", "other"];
  static values = { other: String };

  connect() {
    const form = this.formTarget;
    const vr = this.vrTarget;
    const school = this.schoolTarget;
    const schoolOther = this.schoolOtherTarget;
    const other = this.otherTarget;

    form.addEventListener("submit", (event) => {
      if (vr.value) {
        return;
      }

      event.preventDefault();

      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute("6Lc0uZgUAAAAALEmEx6AkEoASWbkpXNzMVWsekp7", {action: 'ENTRY_FORM_SUBMISSION'});
        vr.value = token;
        form.submit();
      });
    });
  }

  schoolChanged() {
    if (this.schoolTarget.value === this.otherValue) {
      this.otherTarget.classList.remove("hidden");
      this.otherTarget.classList.add("grid");
      this.schoolOtherTarget.required = true;
    } else {
      this.otherTarget.classList.remove("grid");
      this.otherTarget.classList.add("hidden");
      this.schoolOtherTarget.required = false;
    }
  }
}
